// App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Components/AuthContext';
import { AdminAuthProvider } from './Components/AdminAuthContext';
import { ThemeProvider, ThemeContext } from './Components/ThemeContext'; 
import PrivateRouteChangeContent from './Components/PrivateRouteChangeContent';
import ProfessionalRegister from './Pages/Register/Register';
import LoginChangeContent from './Components/LoginChangeContent';
import Dashboard from './Pages/DashboardUser/DashboardUser';
import Home from "./Pages/Home";
import AdminDashboard from './Pages/AdminDashboard/AdminDashboard';
import AdminLogin from './Pages/AdminLogin/AdminLogin';
import ContactPage from './Pages/Contact/Contact';
import Portfolio from './Pages/AboutUs/AboutUs';
import Services from './Pages/Servises/Servises';
import ServiceDetail from './Components/ServiceDetail/ServiceDetail';
import './App.css';
import DashboardForChangeContent from './Pages/DashboardForChangeContent/DashboardForChangeContent';
import ArticlesSection from './Pages/Madona/Madona';
import Login from './Pages/LoginUser/LoginUser';
import TermsAndConditions from './Pages/Conditions/Conditions';
import MnNa7noo from './Pages/MnNa7noo/MnNa7noo';


function App() {
  const { themeMode } = useContext(ThemeContext); // Access the theme mode
  const isDarkMode = themeMode === 'dark'; // Determine if dark mode is active
  
  return (
    <ThemeProvider>
      <AuthProvider>
        <AdminAuthProvider>
          <div id="app-container">
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<ProfessionalRegister />} />
                <Route path="/loginChangeContent" element={<LoginChangeContent />} />
                <Route path="/dashboard/:username" element={<PrivateRouteChangeContent element={<Dashboard />} />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/dashboard" element={<PrivateRouteChangeContent element={<AdminDashboard />} />} />
                <Route path="/contactus" element={<ContactPage />} />
                <Route path="/aboutus" element={<Portfolio />} />
                <Route path="/sevices" element={<Services />} />
                <Route path="/madona" element={<ArticlesSection />} />
                <Route path="/mnnhno" element={<MnNa7noo />} />
                <Route path="/login" element={<Login />} />
                <Route path="/conditions" element={<TermsAndConditions />} />
                <Route path="/service-detail/:serviceName" element={<ServiceDetail />} />
                {/* Protecting the DashboardForChangeContent route */}
                <Route path="/changeContent" element={<DashboardForChangeContent />} />
              </Routes>
            </Router>
          </div>
        </AdminAuthProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
