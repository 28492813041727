import React from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { Container, Typography, Box, Breadcrumbs, Link, Divider, Grid, Paper } from '@mui/material';
import { AccessAlarm, Grade, Lightbulb, Phone, HeadsetMic } from '@mui/icons-material'; // Icons for the new section

// Styles for the component
const headerStyle = {
  width: '100%',
  background: 'linear-gradient(135deg, #e0f7fa 30%, #bbdefb 90%)',
  padding: '50px 0',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30vh',
};

const titleStyle = {
  fontWeight: 'bold',
  fontSize: '48px',
  color: '#333',
  marginBottom: '20px',
  fontFamily: 'Cairo, sans-serif',
};

const breadcrumbStyle = {
  fontSize: '18px',
  color: '#555',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  fontFamily: 'Cairo, sans-serif',
};

const containerStyle = {
  direction: 'rtl',
  textAlign: 'right',
  fontFamily: 'Cairo, sans-serif',
  margin: 'auto',
  padding: '20px',
  marginTop: '20px',
  width: '80%',
  boxSizing: 'border-box',
};

const headingStyle = {
  fontWeight: 'bold',
  fontSize: '26px',
  marginBottom: '10px',
  color: '#1976D2',
  fontFamily: 'Cairo, sans-serif',
};

const paragraphStyle = {
  marginBottom: '15px',
  fontSize: '18px',
  lineHeight: '1.8',
  color: '#333',
  fontFamily: 'Cairo, sans-serif',
};

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#2ac0eb',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  color: '#FFF',
  justifyContent:"center",
  minHeight:"200px"
};

const iconStyle = {
  fontSize: '70px',
  marginBottom: '15px',
  color: '#FFF',
};

const MnNa7noo = () => {
  return (
    <div>
      <NavBar />
      <Box sx={headerStyle}>
        <Box>
          <Typography sx={titleStyle}>مــن نــــحـــن</Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={breadcrumbStyle}>
            <Link href="/" underline="hover" sx={{ color: '#1976D2', cursor: 'pointer' }}>
              الرئيسية
            </Link>
            <Typography style={{ fontFamily: 'Cairo' }} color="textPrimary">
              مــن نــــحـــن
            </Typography>
          </Breadcrumbs>
        </Box>
      </Box>

      <Container maxWidth={false} sx={containerStyle}>
        <Box>
          <Typography sx={paragraphStyle}>
            إرتقي بك إلى آفاق جديدة في عالم الرقمية. نحن في إرتقي، شركاءك في رحلة النمو والتطور الرقمي.
            نقدم لك حلولاً مبتكرة ومتكاملة لتلبية جميع احتياجاتك التسويقية والبرمجية. مع سنوات من الخبرة
            وفريق عمل متميز، نساعدك على بناء حضور رقمي قوي وفعال. اكتشف كيف يمكننا مساعدتك في تحقيق أهدافك.
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />
          <Typography sx={headingStyle}>أهدافنا</Typography>
          <Typography sx={paragraphStyle}>
            <strong>الهدف الأول:</strong> أن نكون الشريك المفضل للشركات في المملكة العربية السعودية في
            مجال التسويق الرقمي والبرمجة، وذلك من خلال تقديم حلول مبتكرة وفعالة تساهم في نمو أعمالهم وتوسيع
            نطاق تأثيرهم الرقمي.
          </Typography>
          <Typography sx={paragraphStyle}>
            <strong>الهدف الثاني:</strong> بناء فريق عمل متميز ومبتكر قادر على مواكبة أحدث التطورات في
            مجال التكنولوجيا والتسويق الرقمي.
          </Typography>
          <Typography sx={paragraphStyle}>
            <strong>الهدف الثالث:</strong> المساهمة في تطوير الاقتصاد الرقمي في المملكة العربية السعودية
            من خلال دعم رواد الأعمال والشركات الناشئة.
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />
          <Typography sx={headingStyle}>رؤيتنا</Typography>
          <Typography sx={paragraphStyle}>
            نحن في إرتقي  نسعى لأن نكون الرائدين في مجال التسويق الرقمي والبرمجة في المملكة العربية السعودية،
            وذلك من خلال توفير حلول مبتكرة تلبي احتياجات عملائنا وتتجاوز توقعاتهم. نطمح إلى أن نكون الشريك
            الاستراتيجي للشركات التي تسعى لتحقيق النمو والتطور المستدام.
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />
          <Typography sx={headingStyle}>رسالتنا</Typography>
          <Typography sx={paragraphStyle}>
            نحن في إرتقي  ملتزمون بتقديم خدمات تسويق وبرمجة عالية الجودة تساهم في تعزيز حضور عملائنا الرقمي وزيادة
            أرباحهم. من خلال شراكتنا مع عملائنا، نساعدهم على بناء علامات تجارية قوية وتعزيز وجودهم في السوق.
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />
          <Typography sx={headingStyle}>خدماتنا</Typography>
          <Typography sx={paragraphStyle}>
            "نحن في إرتقي  نوفر لك حزمة متكاملة من الحلول الرقمية التي تغطي جميع احتياجاتك. بدءًا من تصميم مواقع
            إلكترونية جذابة وسهلة الاستخدام، وصولًا إلى تطوير تطبيقات متطورة، وإدارة حملاتك الإعلانية على مختلف
            المنصات. بالإضافة إلى ذلك، نوفر خدمات تحسين محركات البحث (SEO)، وإدارة متاجر إلكترونية، وإدارة
            حسابات التواصل الاجتماعي، وتصميم الهوية البصرية والجرافيك والموشن جرافيك. هدفنا هو مساعدتك في بناء
            حضور رقمي قوي وفعال."
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />
          <Typography sx={headingStyle}>لماذا تختار إرتقي؟</Typography>
          <Typography sx={paragraphStyle}>
            الخبرة والمعرفة: فريقنا يضم خبراء متمرسين في مجال التسويق الرقمي والبرمجة، مما يضمن لك الحصول على حلول مبتكرة وفعالة.
            <br />
            الحلول المخصصة: نحن لا نقدم حلولًا جاهزة، بل نصمم حلولًا مخصصة لتناسب احتياجات عملك بشكل دقيق.
            <br />
            التركيز على النتائج: هدفنا الرئيسي هو مساعدتك في تحقيق أهدافك وتحقيق النمو المستدام.
            <br />
            الشفافية والوضوح: نؤمن بالشفافية التامة في التعامل مع عملائنا، ونقدم تقارير دورية عن سير العمل.
            <br />
            الدعم المستمر: نوفر لك الدعم الفني والتقني على مدار الساعة طوال أيام الأسبوع.
            <br />
            الجودة العالية: نستخدم أحدث التقنيات والأدوات لضمان جودة الخدمات التي نقدمها.
            <br />
            الأسعار التنافسية: نقدم خدماتنا بأسعار تنافسية تناسب ميزانيتك.
            <br />
            التركيز على العميل: رضى عملائنا هو هدفنا الأول، ونعمل جاهدين لتقديم أفضل تجربة ممكنة.
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />

          {/* New Section - What Sets Us Apart */}
          <Typography sx={{ fontWeight: 'bold', fontSize: '26px', marginBottom: '10px', color: '#1976D2', fontFamily: 'Cairo, sans-serif', textAlign: "center" }}>ما يميزنا؟</Typography>
          <Grid style={{display:"flex",justifyContent:"center"}} container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={cardStyle}>
                <AccessAlarm sx={iconStyle} />
                <Typography style={{ fontFamily: "cairo" }}>الالتزام بالمواعيد</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={cardStyle}>
                <Grade sx={iconStyle} />
                <Typography style={{ fontFamily: "cairo" }}>الجودة</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={cardStyle}>
                <Lightbulb sx={iconStyle} />
                <Typography style={{ fontFamily: "cairo" }}>الابتكار</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={cardStyle}>
                <Phone sx={iconStyle} />
                <Typography style={{ fontFamily: "cairo" }}>التواصل المستمر</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={cardStyle}>
                <HeadsetMic sx={iconStyle} />
                <Typography style={{ fontFamily: "cairo" }}>خدمات ما بعد البيع</Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* New Section - Technologies Used */}
          <Divider sx={{ margin: '20px 0' }} />
          <Typography sx={headingStyle}>التقنيات المستخدمة في شركة إرتقي لإنجاز الأعمال</Typography>
          <Typography sx={paragraphStyle}>
            بما أن شركة إرتقي  تقدم مجموعة واسعة من الخدمات الرقمية، فمن المتوقع أن تستخدم مجموعة متنوعة من التقنيات والأدوات. إليك بعض التقنيات الشائعة التي قد تستخدمها شركة مثل إرتقي  مع توضيح دور كل منها:
          </Typography>

          <Typography sx={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px', color: '#1976D2', fontFamily: 'Cairo, sans-serif' }}>لتصميم وتطوير المواقع والتطبيقات:</Typography>
          <Typography sx={paragraphStyle}>
            <strong>لغات البرمجة:</strong> HTML, CSS, JavaScript, Python, PHP, Ruby on Rails, Node.js، وغيرها.
            <br />
            <strong>أطر العمل:</strong> React, Angular, Vue.js, Laravel, Django، وغيرها.
            <br />
            <strong>قواعد البيانات:</strong> MySQL, PostgreSQL, MongoDB، وغيرها.
            <br />
            <strong>أنظمة إدارة المحتوى (CMS):</strong> WordPress, Joomla, Drupal، وغيرها.
            <br />
            <strong>أدوات التصميم:</strong> Adobe XD, Figma, Sketch، وغيرها.
          </Typography>

          <Typography sx={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px', color: '#1976D2', fontFamily: 'Cairo, sans-serif' }}>لتحسين محركات البحث (SEO) وإدارة الحملات الإعلانية:</Typography>
          <Typography sx={paragraphStyle}>
            <strong>أدوات تحليل SEO:</strong> Google Search Console, SEMrush, Ahrefs، وغيرها.
            <br />
            <strong>أدوات إدارة الحملات الإعلانية:</strong> Google Ads, Facebook Ads Manager، وغيرها.
            <br />
            <strong>أدوات تحليل البيانات:</strong> Google Analytics، وغيرها.
          </Typography>

          <Typography sx={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px', color: '#1976D2', fontFamily: 'Cairo, sans-serif' }}>لإدارة المتاجر الإلكترونية:</Typography>
          <Typography sx={paragraphStyle}>
            <strong>منصات التجارة الإلكترونية:</strong> Shopify, WooCommerce, Magento، وغيرها.
            <br />
            <strong>بوابات الدفع:</strong> PayPal, Stripe، وغيرها.
          </Typography>

          <Typography sx={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px', color: '#1976D2', fontFamily: 'Cairo, sans-serif' }}>لجرافيك ديزاين وموشن جرافيك:</Typography>
          <Typography sx={paragraphStyle}>
            <strong>أدوات التصميم الجرافيكي:</strong> Adobe Photoshop, Illustrator, InDesign، وغيرها.
            <br />
            <strong>أدوات الموشن جرافيك:</strong> Adobe After Effects, Cinema 4D، وغيرها.
          </Typography>

          {/* New Section - Technologies Image */}
            {/* Technologies Grid Section */}
<Divider sx={{ margin: '20px 0' }} />
<Typography sx={{  fontWeight: 'bold',
  fontSize: '30px',
  marginBottom: '10px',
  color: '#1976D2',
  textAlign:"center",
  fontFamily: 'Cairo, sans-serif',}}>التقنيات المستخدمة</Typography>
<Box sx={{ textAlign: 'center', padding: '20px 0' }}>
  <Grid container spacing={0} justifyContent="center">
    {/* Row 1 */}
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg" alt="Figma" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg" alt="Adobe XD" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/d/d1/Ionic_Logo.svg" alt="Ionic" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5v4dY-tqGmH47hMuTsVg1dN_TqZAxRtNviA&s" alt="Objective-C" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Laravel.svg" alt="Laravel" style={{ width: '80px', height: '80px' }} />
    </Grid>

    {/* Row 2 */}
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/7/74/Kotlin_Icon.png" alt="Kotlin" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="React" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/d/d7/Android_robot.svg" alt="Android" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/9/9d/Swift_logo.svg" alt="Swift" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Adobe_Illustrator_CC_icon.svg" alt="Illustrator" style={{ width: '80px', height: '80px' }} />
    </Grid>

    {/* Row 3 */}
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/62/CSS3_logo.svg" alt="CSS3" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg" alt="Vue.js" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/c/cf/Angular_full_color_logo.svg" alt="Angular" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" alt="JavaScript" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/1/17/Google-flutter-logo.png" alt="Flutter" style={{ width: '80px', height: '80px' }} />
    </Grid>

    {/* Row 4 */}
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" alt="Python" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg" alt="Node.js" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg" alt="PHP" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Nuxt_logo.svg" alt="Nuxt.js" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg" alt="HTML5" style={{ width: '80px', height: '80px' }} />
    </Grid>

    {/* Row 5 */}
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://cdn-icons-png.freepik.com/512/5395/5395370.png" alt="Cloud" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://images.ctfassets.net/po4qc9xpmpuh/3DXFDcf1EO2D7mwb2r7RTM/62e5c1b9a070d21a0288d9dc9148a914/nosql.png" alt="NoSQL" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/8/87/Sql_data_base_with_logo.png" alt="SQL" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/en/3/30/Java_programming_language_logo.svg" alt="Java" style={{ width: '80px', height: '80px' }} />
    </Grid>
    <Grid item xs={6} sm={2.4} sx={{ border: '1px solid #e0e0e0', padding: '10px' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/7/7d/Microsoft_.NET_logo.svg" alt=".NET" style={{ width: '80px', height: '80px' }} />
    </Grid>
  </Grid>
</Box>




        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default MnNa7noo;
