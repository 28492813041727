import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Portfolio.css'; // Create this CSS file for styling
import ProfessionalSection from './SecondSection/SecondSection';

const Portfolio = () => {
  const navigate = useNavigate();
  const [portfolioData, setPortfolioData] = useState(null); // State to store fetched data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const handleLogoClick = () => {
    navigate('/');
  };

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const response = await axios.get('https://backendforerpsystem.zbooma.com/get_portfolio_content.php');
        if (response.data.success) {
          setPortfolioData(response.data.data[0]); // Assuming data is in an array, take the first item
        } else {
          setError('Failed to fetch portfolio data');
        }
      } catch (error) {
        setError('Error fetching data from the server');
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    fetchPortfolioData();
  }, []);

  // Handle loading and error states
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="portfolio-container">
        <header className="header">
          <div style={{ letterSpacing: "5px", cursor: "pointer" }} className="logo" onClick={handleLogoClick}>
            <img
              src={`https://backendforerpsystem.zbooma.com${portfolioData.header_image_url}`}
              style={{ width: '80px', height: '102px' }} // Adjust as necessary
              alt="logo"
            />
          </div>
          <button style={{fontSize:"25px"}} className="contact-button">{portfolioData.header_button_text}</button>
        </header>
        <section className="content-section">
          <div style={{textAlign:"right"}} className="text-div">
            <h1 style={{textAlign:"right"}}>{portfolioData.title}</h1>
            <p style={{textAlign:"right",fontSize:"25px"}}>{portfolioData.subtitle}</p>
            <button style={{fontSize:"25px"}} className="contact-button">{portfolioData.button_text}</button>
          </div>
          <div className="image-div">
            <img
              src={`https://backendforerpsystem.zbooma.com${portfolioData.portfolio_image_url}`}
              alt="Portfolio Illustration"
            />
          </div>
        </section>
      </div>
      <ProfessionalSection />
    </>
  );
};

export default Portfolio;
