import { Box, Grid, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Dot icon
import TikTokIcon from '@mui/icons-material/MusicNote'; // Import a TikTok-like icon for the placeholder
import CameraAltIcon from '@mui/icons-material/CameraAlt'; // Placeholder icon for Snapchat
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import image from "../../images/ارتقي 2.svg"; // Your logo image path
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
const Footer = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleExternalLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ backgroundColor: '#30355b', padding: '40px 0', color: 'white', paddingTop: '100px' }}>
      <Box sx={{ width: '90%', margin: '0 auto' }}>
        <Grid container spacing={4}>
          {/* Contact Information */}
          <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: '"MO_Nawel", sans-serif',
                borderBottom: '1px solid white',
                paddingBottom: '10px',
                marginBottom: '20px',
                fontWeight: 'bold',
                fontSize: '24px',
              }}
            >
              تواصل معنا
            </Typography>
            <Box sx={{ fontFamily: '"MO_Nawel", sans-serif', lineHeight: '2', fontSize: '18px' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                <PhoneIcon sx={{ marginLeft: '10px', fontSize: '30px' }} /> +966553165725
              </Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                <EmailIcon sx={{ marginLeft: '10px', fontSize: '30px' }} /> info@ertaqyagency-e.com
              </Typography>
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: '"MO_Nawel", sans-serif',
                borderBottom: '1px solid white',
                paddingBottom: '10px',
                marginBottom: '20px',
                fontWeight: 'bold',
                fontSize: '24px',
              }}
            >
              الروابط السريعة
            </Typography>

            {/* Quick Links with Dots */}
            <Grid container spacing={2} style={{ textAlign: 'right', direction: 'rtl' }}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px', cursor: 'pointer' }}
                  onClick={() => handleNavigation('/')}
                >
                  <FiberManualRecordIcon sx={{ fontSize: '12px', color: 'white', marginLeft: '10px' }} />
                  <Typography sx={{ fontFamily: '"MO_Nawel", sans-serif', fontSize: '30px' }}>الرئيسية</Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px', cursor: 'pointer' }}
                  onClick={() => handleNavigation('/contactus')}
                >
                  <FiberManualRecordIcon sx={{ fontSize: '12px', color: 'white', marginLeft: '10px' }} />
                  <Typography sx={{ fontFamily: '"MO_Nawel", sans-serif', fontSize: '30px' }}>إتصل بنا</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px', cursor: 'pointer' }}
                  onClick={() => handleNavigation('/mnnhno')}
                >
                  <FiberManualRecordIcon sx={{ fontSize: '12px', color: 'white', marginLeft: '10px' }} />
                  <Typography sx={{ fontFamily: '"MO_Nawel", sans-serif', fontSize: '30px' }}>من نحن</Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px', cursor: 'pointer' }}
                  onClick={() => handleNavigation('/conditions')}
                >
                  <FiberManualRecordIcon sx={{ fontSize: '12px', color: 'white', marginLeft: '10px' }} />
                  <Typography sx={{ fontFamily: '"MO_Nawel", sans-serif', fontSize: '30px' }}>الشروط</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Logo and Social Media */}
          <Grid item xs={12} md={4} sx={{ textAlign: 'right', marginTop: '-50px' }}>
            <Box sx={{ textAlign: 'center' }}>
              <img src={image} alt="Logo" style={{ maxWidth: '200px', marginBottom: '20px' }} />
              <Typography sx={{ fontFamily: '"MO_Nawel", sans-serif', marginBottom: '10px', fontSize: '18px', textAlign: 'center' }}>
                تابعنا على منصات التواصل
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                {/* <FacebookIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => handleExternalLink('https://www.facebook.com')}
                /> */}
                {/* <TwitterIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => handleExternalLink('https://twitter.com')}
                /> */}
                <InstagramIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => handleExternalLink('https://www.instagram.com/ertaqysa/')}
                />
                <LinkedInIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => handleExternalLink('https://www.linkedin.com/company/ertaqycompany/')}
                />
                {/* <YouTubeIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => handleExternalLink('https://www.youtube.com')}
                /> */}
                <TikTokIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => handleExternalLink('https://www.tiktok.com/@ertaqy1?is_from_webapp=1&sender_device=pc')}
                />
                <NotificationsNoneIcon
                  sx={{ color: 'white', fontSize: '30px', cursor: 'pointer' }} // Add Snapchat icon and styles
                 
                />              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Google Map Embed */}
        <Box sx={{ marginTop: '40px' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3713.5969086008904!2d39.8026111!3d21.4450833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDI2JzQyLjMiTiAzOcKwNDgnMDkuNCJF!5e0!3m2!1sar!2seg!4v1724569733802!5m2!1sar!2seg"
            width="100%"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
