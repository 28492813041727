import React from 'react';
import { Container, Typography, Box, Breadcrumbs, Link, Divider, Paper } from '@mui/material';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';

// Define a container style to set RTL and Cairo font
const containerStyle = {
  direction: 'rtl',
  textAlign: 'right',
  fontFamily: 'Cairo, sans-serif', // Apply Cairo font globally
  margin: 'auto',
  padding: '20px',
  marginTop: '20px',
  width: '80%',
  boxSizing: 'border-box',
};

// Styles for the header section
const headerStyle = {
  width: '100%',
  background: 'linear-gradient(135deg, #e0f7fa 30%, #bbdefb 90%)',
  padding: '50px 0',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30vh',
};

const titleStyle = {
  fontWeight: 'bold',
  fontSize: '48px',
  color: '#333',
  marginBottom: '20px',
  fontFamily: 'Cairo, sans-serif',
};

const breadcrumbStyle = {
  fontSize: '18px',
  color: '#555',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  fontFamily: 'Cairo, sans-serif',
};

// Style for the condition titles
const headingStyle = {
  fontWeight: 'bold',
  fontSize: '26px',
  marginBottom: '10px',
  color: '#1976D2', // Use a distinct color for titles
  fontFamily: 'Cairo, sans-serif',
};

// Style for paragraphs with a border
const paragraphBoxStyle = {
  padding: '15px 20px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #ddd', // Add a border around each condition
  borderRadius: '8px',
  marginBottom: '20px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Cairo, sans-serif',
};

// Paragraph text style
const paragraphStyle = {
  marginBottom: '15px',
  fontSize: '18px',
  lineHeight: '1.8',
  color: '#333',
  fontFamily: 'Cairo, sans-serif',
};

// Divider style for visual separation
const dividerStyle = {
  margin: '20px 0',
  borderColor: '#ddd',
};

// Public header text style
const publicHeaderStyle = {
  fontSize: '20px',
  fontWeight: '400',
  marginBottom: '30px',
  padding: '15px',
  color: '#444',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Cairo, sans-serif',
};

const TermsAndConditions = () => {
  return (
    <div>
      <NavBar />
      {/* Header Section */}
      <Box sx={headerStyle}>
        <Box>
          <Typography sx={titleStyle}>الشروط والأحكام</Typography>
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={breadcrumbStyle}>
            <Link href="/" underline="hover" sx={{ color: '#1976D2', cursor: 'pointer' }}>
              الرئيسية
            </Link>
            <Typography style={{ fontFamily: 'Cairo' }} color="textPrimary">
              الشروط والأحكام
            </Typography>
          </Breadcrumbs>
        </Box>
      </Box>

      {/* Public Header Text */}
      <Container maxWidth={false} sx={containerStyle}>
        <Box sx={publicHeaderStyle}>
          تضع شركة إرتقي هذه الشروط والأحكام لضمان تقديم أفضل خدمة لعملائها، وتنظيم العلاقة التعاقدية بين الطرفين. يرجى قراءة هذه الشروط بعناية قبل طلب أي من خدماتنا، حيث يشكل الموافقة عليها قبولاً كاملاً بها.
        </Box>

        {/* Condition Sections */}
        <Box>
          <Typography sx={headingStyle}>البند الأول: تعريف المصطلحات</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              الشركة: تعني شركة إرتقي .
              <br />
              العميل: أي شخص أو جهة تقوم بطلب الخدمات من الشركة.
              <br />
              الخدمات: تشمل جميع الخدمات التي تقدمها الشركة، مثل تصميم المواقع، تطوير التطبيقات، التسويق الرقمي، وغيرها.
              <br />
              الموقع: الموقع الإلكتروني للشركة أو أي منصة أخرى تستخدمها الشركة للتواصل مع العملاء.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند الثاني: الخدمات المقدمة</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              تقدم الشركة مجموعة واسعة من الخدمات الرقمية، والتي يتم تحديدها بشكل تفصيلي في العقد المبرم بين الطرفين.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند الثالث: رسوم الخدمات</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              تحدد رسوم الخدمات في العقد المبرم بين الطرفين، وهي قابلة للتغيير وفقًا للتعديلات التي يطلبها العميل.
              <br />
              يلتزم العميل بسداد الرسوم المتفق عليها في المواعيد المحددة.
              <br />
              في حالة التأخير في السداد، يحق للشركة وقف الخدمات المقدمة إلى حين سداد المبالغ المستحقة.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند الرابع: ملكية المحتوى</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              يظل المحتوى الذي يقدمه العميل ملكًا له، إلا أن الشركة تحصل على الحق في استخدامه لأغراض التسويق والعرض التقديمي.
              <br />
              تصميمات المواقع والتطبيقات التي تقوم الشركة بتطويرها ملكًا للشركة، إلا أن حقوق الاستخدام النهائية تكون للعميل.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند الخامس: المسؤوليات</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              تلتزم الشركة بتقديم الخدمات المتفق عليها بجودة عالية وبحسب المعايير المتفق عليها.
              <br />
              يلتزم العميل بتزويد الشركة بجميع المعلومات اللازمة لإنجاز الخدمات المطلوبة.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند السادس: سرية المعلومات</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              تلتزم الشركة بسرية جميع المعلومات التي يحصل عليها من العميل، ولا تقوم بتسريبها لأي طرف ثالث.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند السابع: حل النزاعات</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              أي نزاع ينشأ عن هذا العقد يحال إلى التحكيم التجاري أو القضاء المختص.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند الثامن: القوة القاهرة</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              لا تتحمل الشركة مسؤولية أي تأخير أو عدم تنفيذ للالتزامات الناجمة عن أسباب خارجة عن إرادتها مثل الحروب، الكوارث الطبيعية، أو أي ظروف قاهرة أخرى.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند التاسع: التعديلات</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              يمكن تعديل هذه الشروط والأحكام من قبل الشركة في أي وقت، ويتم إبلاغ العملاء بالتعديلات الجديدة عن طريق النشر على الموقع الإلكتروني للشركة أو عن طريق البريد الإلكتروني.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Typography sx={headingStyle}>البند العاشر: القانون الحاكم</Typography>
          <Paper elevation={0} sx={paragraphBoxStyle}>
            <Typography sx={paragraphStyle}>
              يحكم هذا العقد ويطبق عليه قانون دولة المملكة العربية السعودية.
            </Typography>
          </Paper>
          <Divider sx={dividerStyle} />

          <Box sx={{ fontStyle: 'italic', color: 'gray', marginTop: '20px' }}>
            ملاحظة: هذه الشروط والأحكام هي نموذج عام، وقد تحتاج إلى تعديلها لتناسب طبيعة عمل شركتك والخدمات التي تقدمها. يفضل استشارة محامٍ متخصص في مجال القانون التجاري لتعديل هذه الشروط بما يتناسب مع احتياجاتك.
          </Box>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
