import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Swal from 'sweetalert2';
// Material UI Components
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const VideoGallery = () => {
  const [videos, setVideos] = useState([]);
  const [newVideoSrc, setNewVideoSrc] = useState("");
  const [newVideoTitle, setNewVideoTitle] = useState(""); // For adding video
  const [updateVideoId, setUpdateVideoId] = useState(null);
  const [deleteVideoId, setDeleteVideoId] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false); // Dialog for adding a new video

  useEffect(() => {
    // Fetch videos from the API
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/getvideosSlidernew.php');
        const data = await response.json();

        if (data.success) {
          setVideos(data.data);  // Set the fetched videos into state
        } else {
          Swal.fire('Error', data.message, 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch videos', 'error');
      }
    };

    fetchVideos();
  }, []);

  // Handle adding a new video
  const handleAddVideo = async () => {
    if (!newVideoSrc || !newVideoTitle) {
      Swal.fire('Error', 'Please enter both a video link and title.', 'error');
      return;
    }

    try {
      const response = await fetch('https://backendforerpsystem.zbooma.com/addVideonew.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          video_src: newVideoSrc,
          title: newVideoTitle,
        }),
      });
      const data = await response.json();

      if (data.success) {
        setVideos([...videos, { id: data.id, video_src: newVideoSrc, title: newVideoTitle }]); // Update UI with new video
        setOpenAddDialog(false); // Close dialog after successful addition
        Swal.fire('Added!', 'The video has been added.', 'success');
      } else {
        Swal.fire('Error', data.message, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to add video', 'error');
    }
  };

  // Handle deleting a video
  const handleDelete = async () => {
    try {
      const response = await fetch(`https://backendforerpsystem.zbooma.com/deleteVideonew.php?id=${deleteVideoId}`, {
        method: 'DELETE',
      });
      const data = await response.json();

      if (data.success) {
        setVideos(videos.filter((video) => video.id !== deleteVideoId)); // Update UI by removing the deleted video
        setOpenDeleteDialog(false);  // Close dialog
        Swal.fire('Deleted!', 'The video has been deleted.', 'success');
      } else {
        Swal.fire('Error', data.message, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to delete video', 'error');
    }
  };

  // Open update dialog with the current video ID
  const handleOpenUpdateDialog = (id, video_src) => {
    setUpdateVideoId(id);
    setNewVideoSrc(video_src);  // Set the existing video URL to edit
    setOpenUpdateDialog(true);  // Open the dialog
  };

  // Handle updating a video link
  const handleUpdate = async () => {
    if (!newVideoSrc) {
      Swal.fire('Error', 'Please enter a new video link.', 'error');
      return;
    }

    try {
      const response = await fetch('https://backendforerpsystem.zbooma.com/updateVideonew.php', {
        method: 'PUT', // Change to PUT
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: updateVideoId,
          video_src: newVideoSrc,
        }),
      });
      const data = await response.json();

      if (data.success) {
        setVideos(
          videos.map((video) =>
            video.id === updateVideoId ? { ...video, video_src: newVideoSrc } : video
          )
        );
        setOpenUpdateDialog(false);  // Close the dialog after update
        Swal.fire('Updated!', 'The video link has been updated.', 'success');
      } else {
        Swal.fire('Error', data.message, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to update video link', 'error');
    }
  };

  return (
    <div style={{ padding: '40px 0', backgroundColor: '#f4f4f4' }}>
      <div style={{ width: "80%", margin: "auto", textAlign: 'center' }}>
        <h2 style={{ fontFamily: '"MO_Nawel", sans-serif', fontSize: '36px', marginBottom: '20px' }}>
        الإنتاج المرئى
        </h2>

        {/* Button to open the Add Video dialog */}
        <Button style={{margin:"20px"}} variant="contained" color="primary" onClick={() => setOpenAddDialog(true)}>
          Add New Video
        </Button>

        {videos.length > 0 ? (
          <Swiper
            modules={[Navigation]}
            autoplay={false}
            spaceBetween={30}
            slidesPerView={2}
            navigation  // Enable navigation but disable autoplay and pagination
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 }
            }}
          >
            {videos.map((video) => (
              <SwiperSlide key={video.id}>
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '56.25%',  // 16:9 aspect ratio
                    height: 0,
                    overflow: 'hidden',
                    borderRadius: '12px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <iframe
                    src={video.video_src}
                    title={video.title}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '12px',
                    }}
                    allowFullScreen
                    frameBorder="0"
                  ></iframe>
                </div>
                <h3 style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', marginTop: '10px' }}>
                  {video.title}
                </h3>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
                  <IconButton color="primary" onClick={() => handleOpenUpdateDialog(video.id, video.video_src)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => { setDeleteVideoId(video.id); setOpenDeleteDialog(true); }}>
                    <Delete />
                  </IconButton>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p>Loading videos...</p>
        )}
      </div>

      {/* Add Video Dialog */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add New Video</DialogTitle>
        <DialogContent>
          <TextField
            label="Video Title"
            fullWidth
            value={newVideoTitle}
            onChange={(e) => setNewVideoTitle(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Video Link"
            fullWidth
            value={newVideoSrc}
            onChange={(e) => setNewVideoSrc(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddVideo} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* Update Video Dialog */}
      <Dialog open={openUpdateDialog} onClose={() => setOpenUpdateDialog(false)}>
        <DialogTitle>Update Video Link</DialogTitle>
        <DialogContent>
          <iframe
            src={newVideoSrc}
            title="current-video"
            style={{ width: '100%', height: '200px', marginBottom: '20px' }}
            allowFullScreen
          ></iframe>
          <TextField
            label="Video Link"
            fullWidth
            value={newVideoSrc}
            onChange={(e) => setNewVideoSrc(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpdateDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this video?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VideoGallery;
