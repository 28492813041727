import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ServiceNew = () => {
  const [videoSlides, setVideoSlides] = useState([]);

  // Fetch videos from the API
  useEffect(() => {
    const fetchVideos = async () => {
      try { 
        const response = await fetch('https://backendforerpsystem.zbooma.com/getvideosSlidernew.php');
        const data = await response.json();
        
        if (data.success) {
          setVideoSlides(data.data);
        } else {
          console.error('Failed to fetch videos:', data.message);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <>
      <style>{`
        .container {
          max-width: 80%;
          margin: 0 auto;
          padding: 40px 0;
          position: relative;
        }

        .responsive-iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }

        /* Adjust iframe size and hide arrows on smaller screens */
        @media (max-width: 640px) {
           .container {
          max-width: 95%;
          margin: 0 auto;
          padding: 40px 0;
          position: relative;
        }
          .responsive-iframe {
            width: 100%;
            height: 60vw;
          }
          .swiper-button-prev,
          .swiper-button-next {
            display: none !important; /* Hide arrows on small screens */
            height: 0 !important; /* Ensure they don't take space */
          }
        }

        .swiper-button-prev, .swiper-button-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: #42aaa6;
          color: white;
          font-size: 24px;
          z-index: 10;
          cursor: pointer;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .swiper-button-prev {
          left: -30px; /* Default position */
        }

        .swiper-button-next {
          right: -30px; /* Default position */
        }

        .swiper-pagination {
          bottom: -30px;
        }
      `}</style>
      
      <div style={{ width: "90%", marginTop: "-40px" }}>
        <h3 style={{ color: "white", fontFamily: '"MO_Nawel", sans-serif', textAlign: "right", fontSize: "35px",paddingTop:"50px"}}>
          الإنتاج المرئى
        </h3>
      </div>
      <div className="container">
        {videoSlides.length > 0 ? (
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={1}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: false,
            // }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            spaceBetween={30}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
              bulletClass: 'swiper-pagination-bullet',
              bulletActiveClass: 'swiper-pagination-bullet-active',
              renderBullet: (index, className) => {
                return `<span class="${className}" style="background-color: white;"></span>`;
              },
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className="swiper_container"
            style={{ height: '600px', width: '100%' }}
          >
            {videoSlides.map((slide) => (
              <SwiperSlide key={slide.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                  <iframe
                    src={slide.video_src}
                    title={slide.title}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    className="responsive-iframe"
                  ></iframe>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p>Loading videos...</p>
        )}

        {/* Custom Navigation Arrows */}
        <div className="swiper-button-prev">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
        <div className="swiper-button-next">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div className="swiper-pagination"></div> {/* Move dots below the slider */}
      </div>
    </>
  );
};

export default ServiceNew;
