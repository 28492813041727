// PrivateRouteChangeContent.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRouteChangeContent = ({ element }) => {
  const { isAdminAuthenticated } = useContext(AuthContext); // Check for admin authentication

  return isAdminAuthenticated ? element : null; // Redirect to admin login if not authenticated
};

export default PrivateRouteChangeContent;
