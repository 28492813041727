import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import './AppointmentBooking.css';
import Image1 from "../../../images/Ellipse 11.svg";
import Image2 from "../../../images/Ellipse 12.svg";

const AppointmentBooking = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    date: null,
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      date: date ? date.toLocaleDateString('en-CA') : '' // Format date as YYYY-MM-DD
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const appointmentData = {
      name: formData.name,
      phone: formData.phone,
      date: formData.date, 
      email: formData.email,
    };

    try {
      const response = await fetch('https://backendforerpsystem.zbooma.com/appointment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(appointmentData),
      });

      const result = await response.json();

      if (result.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Appointment booked successfully!',
          confirmButtonText: 'OK',
        });

        setFormData({
          name: '',
          phone: '',
          date: null,
          email: '',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: result.message,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while booking the appointment. Please try again later.',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div className="appointment-section">
      <div className="appointment-card">
        <h2>Appointment Booking</h2>
        <img src={Image1} alt="Decoration" className="small-item top-right" />
        <img src={Image2} alt="Decoration" className="small-item bottom-left" />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="NAME"
              value={formData.name}
              onChange={handleChange}
              required
              className="form-input"
            />
            <input
              type="text"
              name="phone"
              placeholder="PHONE NUMBER"
              value={formData.phone}
              onChange={handleChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <DatePicker
              selected={formData.date ? new Date(formData.date) : null}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              placeholderText="SELECT DATE"
              className="form-input datepicker-input"
            />
            <input
              type="email"
              name="email"
              placeholder="EMAIL"
              value={formData.email}
              onChange={handleChange}
              required
              className="form-input"
            />
          </div>
          <button type="submit" className="send-button">SEND</button>
        </form>
      </div>
    </div>
  );
};

export default AppointmentBooking;
