import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import axios from 'axios';

const API_URL = 'https://backendforerpsystem.zbooma.com/get_stats_section.php';

const StatsSection = () => {
  const [statsData, setStatsData] = useState({
    title: '',
    subtitle: '',
    background_image: '',
    cards: [],
  });
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const [startCount, setStartCount] = useState(false);

  useEffect(() => {
    axios.get(API_URL)
      .then((response) => {
        if (response.data.success) {
          setStatsData(response.data.data);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (inView && !startCount) {
    setStartCount(true);
  }

  return (
    <Box
      ref={inViewRef}
      sx={{
        position: 'relative',
        height: { xs: 'auto', md: '700px' },
        backgroundImage: `url(https://backendforerpsystem.zbooma.com/${statsData.background_image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'right',
        padding: '20px',
        marginTop: { xs: '100px', md: '0' },
      }}
    >
      {/* Dark overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          zIndex: 1,
        }}
      />

      {/* Content on top of the overlay */}
      <Box sx={{ position: 'relative', zIndex: 2, width: '100%', padding: { xs: '0 10px', md: '0 20px' } }}>
        <Typography variant="h3" sx={{ fontFamily: '"MO_Nawel", sans-serif', textAlign: 'right', paddingRight: '20px', fontSize: { xs: '40px', md: '60px' }, fontWeight: '400' }}>
          {statsData.title}
          <br />
          {statsData.subtitle}
        </Typography>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginTop: '30px' }}>
          {statsData.cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  backgroundColor: '#242636',
                  borderRadius: '15px',
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  minHeight: '200px',
                  textAlign: 'center',
                }}
              >
                <Typography variant="h2" sx={{ fontFamily: '"MO_Nawel", sans-serif', fontWeight: 'bold', fontSize: { xs: '30px', md: '48px' } }}>
                  {startCount && <CountUp start={0} end={parseInt(card.count)} duration={6} />}+
                </Typography>
                <Typography variant="h6" sx={{ fontFamily: '"MO_Nawel", sans-serif', marginTop: '10px', fontSize: { xs: '16px', md: '24px' } }}>
                  {card.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default StatsSection;
