import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid, Button, TextField, Typography, Paper, Box, InputLabel, Select, MenuItem, FormControl,
  IconButton, CardMedia, CircularProgress
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Upload icon
import Swal from 'sweetalert2';

const MdowanaManagement = () => {
  const [articles, setArticles] = useState([]);
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState(''); // Short description for the article
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [steps, setSteps] = useState([{ title: '', description: '', image: null, imagePreview: '' }]); // Array for steps
  const [editingId, setEditingId] = useState(null);
  const [categories, setCategories] = useState(['إنشاء مواقع إلكترونية', 'تطوير الأعمال', 'تحليل', 'التجارة الإلكترونية', 'التعليم الإلكتروني']);
  const [loading, setLoading] = useState(false);

  // Fetch all articles
  useEffect(() => {
    axios.get('https://backendforerpsystem.zbooma.com/articles.php')
      .then(response => {
        setArticles(response.data);
      })
      .catch(error => {
        Swal.fire({
          title: 'خطأ!',
          text: 'حدث خطأ أثناء تحميل المقالات!',
          icon: 'error',
        });
      });
  }, []);

  // Handle adding a new category
  const handleAddCategory = () => {
    if (newCategory && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
      setCategory(newCategory);
      setNewCategory(''); // Clear new category input
      Swal.fire({
        title: 'تمت الإضافة!',
        text: 'تمت إضافة الفئة الجديدة بنجاح.',
        icon: 'success',
      });
    }
  };

  // Handle image upload
  const handleImageChange = (e, stepIndex = -1) => {
    const file = e.target.files[0];
    if (stepIndex === -1) {
      // Main article image
      setImage(file);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => setImagePreview(reader.result);
        reader.readAsDataURL(file);
      }
    } else {
      // Step image
      const updatedSteps = [...steps];
      updatedSteps[stepIndex].image = file;
      const reader = new FileReader();
      reader.onloadend = () => {
        updatedSteps[stepIndex].imagePreview = reader.result;
        setSteps(updatedSteps);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle adding new step
  const addStep = () => {
    setSteps([...steps, { title: '', description: '', image: null, imagePreview: '' }]);
  };

  // Handle removing step
  const removeStep = (index) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  // Handle input changes for steps
  const handleStepChange = (index, field, value) => {
    const updatedSteps = [...steps];
    updatedSteps[index][field] = value;
    setSteps(updatedSteps);
  };

  // Handle adding and editing articles
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('short_description', shortDescription); // Include short description
    formData.append('description', description);
    formData.append('category', category || newCategory);
  
    if (image) formData.append('image', image); // Main article image
  
    steps.forEach((step, index) => {
      formData.append(`steps[${index}][title]`, step.title);
      formData.append(`steps[${index}][description]`, step.description);
      if (step.image) formData.append(`steps[${index}][image]`, step.image);
    });
  
    if (editingId) formData.append('id', editingId);
  
    // Log the FormData to console
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    const url = 'https://backendforerpsystem.zbooma.com/articles.php';
  
    axios({
      method: 'POST',
      url: url,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })
      .then((response) => {
        // Success message
        Swal.fire({
          title: 'نجاح!',
          text: editingId ? 'تم تحديث المقال بنجاح!' : 'تم حفظ المقال بنجاح!',
          icon: 'success',
        }).then(() => window.location.reload());
      })
      .catch((error) => {
        // Error handling with logs
        console.error('Error while saving article:', error); // Log the error in the console
  
        if (error.response && error.response.data && error.response.data.message) {
          // Show specific backend error message if available
          Swal.fire({
            title: 'خطأ!',
            text: error.response.data.message, // Display error message from server
            icon: 'error',
          });
        } else {
          Swal.fire({
            title: 'خطأ!',
            text: 'حدث خطأ أثناء حفظ المقال!', // General error message
            icon: 'error',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  // Handle deleting an article with confirmation
  const handleDelete = (id) => {
    Swal.fire({
      title: 'هل أنت متأكد؟',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم، احذفه!',
      cancelButtonText: 'إلغاء'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios({
          method: 'DELETE',
          url: `https://backendforerpsystem.zbooma.com/articles.php`,
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({ id }),
        }).then(() => {
          Swal.fire('تم الحذف!', 'تم حذف المقال بنجاح.', 'success');
          setArticles(articles.filter(article => article.id !== id));
        }).catch(() => {
          Swal.fire('خطأ!', 'حدث خطأ أثناء حذف المقال!', 'error');
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  };

  // Handle resetting form and cancel editing
  const handleCancelEdit = () => {
    setEditingId(null);
    setTitle('');
    setDescription('');
    setShortDescription(''); // Reset short description
    setCategory('');
    setImage(null);
    setImagePreview('');
    setSteps([{ title: '', description: '', image: null, imagePreview: '' }]); // Reset steps
    Swal.fire({ title: 'إلغاء التعديل', text: 'تم إلغاء التعديل بنجاح.', icon: 'info' });
  };

  return (
    <Box sx={{ padding: '20px', direction: 'rtl', fontFamily: 'Cairo, sans-serif' }}>
      <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}>
        إدارة المدونة
      </Typography>

      <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px', fontFamily: 'Cairo, sans-serif' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="عنوان المقال" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} required sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="وصف مختصر" fullWidth value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} required sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="الوصف" fullWidth multiline rows={4} value={description} onChange={(e) => setDescription(e.target.value)} required sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel sx={{ fontFamily: 'Cairo, sans-serif' }}>الفئة</InputLabel>
                <Select fullWidth value={category} onChange={(e) => setCategory(e.target.value)} sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }}>
                  {categories.map((cat, index) => (
                    <MenuItem key={index} value={cat} sx={{ fontFamily: 'Cairo, sans-serif' }}>{cat}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Field to add new category */}
            <Grid item xs={12}>
              <TextField label="أضف فئة جديدة" fullWidth value={newCategory} onChange={(e) => setNewCategory(e.target.value)} sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} />
              <Button variant="outlined" color="primary" onClick={handleAddCategory} sx={{ marginTop: '10px', fontFamily: 'Cairo, sans-serif' }}>
                إضافة فئة جديدة
              </Button>
            </Grid>

            {/* Steps Section */}
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <TextField label={`عنوان الخطوة ${index + 1}`} fullWidth value={step.title} onChange={(e) => handleStepChange(index, 'title', e.target.value)} sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField label={`وصف الخطوة ${index + 1}`} fullWidth multiline rows={2} value={step.description} onChange={(e) => handleStepChange(index, 'description', e.target.value)} sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <IconButton component="label">
                    <CloudUploadIcon fontSize="large" />
                    <input type="file" accept="image/*" hidden onChange={(e) => handleImageChange(e, index)} />
                  </IconButton>
                  {step.imagePreview && (
                    <CardMedia component="img" image={step.imagePreview} alt={`Preview ${index + 1}`} sx={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                  )}
                </Grid>
                <Button onClick={() => removeStep(index)} variant="outlined" color="secondary" sx={{ fontFamily: 'Cairo, sans-serif' }}>حذف الخطوة</Button>
              </React.Fragment>
            ))}
            <Button onClick={addStep} variant="outlined" color="primary" sx={{ fontFamily: 'Cairo, sans-serif' }}>إضافة خطوة جديدة</Button>

            {/* Main image upload */}
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <IconButton component="label">
                <CloudUploadIcon fontSize="large" />
                <input type="file" accept="image/*" hidden onChange={(e) => handleImageChange(e, -1)} />
              </IconButton>
              {imagePreview && (
                <CardMedia component="img" image={imagePreview} alt="Preview" sx={{ width: '200px', height: '200px', objectFit: 'cover' }} />
              )}
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" type="submit" sx={{ fontFamily: 'Cairo, sans-serif' }} disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : editingId ? 'تحديث المقال' : 'حفظ المقال'}
              </Button>
              {editingId && (
                <Button variant="outlined" color="secondary" onClick={handleCancelEdit} sx={{ fontFamily: 'Cairo, sans-serif' }} disabled={loading}>
                  إلغاء التعديل
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}>جميع المقالات</Typography>
      {articles.map((article) => (
        <Paper key={article.id} elevation={1} sx={{ padding: '10px', marginBottom: '10px', fontFamily: 'Cairo, sans-serif' }}>
          <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>{article.title}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif' }}>{article.description}</Typography>
          <Typography variant="caption" sx={{ fontFamily: 'Cairo, sans-serif' }}>{article.category}</Typography>

          {article.image && (
            <CardMedia component="img" image={article.image} alt={article.title} sx={{ width: '200px', height: '200px', borderRadius: '8px', border: '1px solid #ddd', objectFit: 'cover', marginTop: '10px' }} />
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <Button variant="contained" color="secondary" onClick={() => handleDelete(article.id)} sx={{ fontFamily: 'Cairo, sans-serif' }} disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'حذف'}
            </Button>
            <Button variant="outlined" color="primary" onClick={() => {
              setEditingId(article.id);
              setTitle(article.title);
              setDescription(article.description);
              setCategory(article.category);
              setImagePreview(article.image);
            }} sx={{ fontFamily: 'Cairo, sans-serif' }}>
              تعديل
            </Button>
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default MdowanaManagement;
