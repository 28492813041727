import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ThemeContext } from '../ThemeContext';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { green } from '@mui/material/colors';

const API_URL = 'https://backendforerpsystem.zbooma.com/getPartners.php'; // API endpoint

const sliderSettings = {
  infinite: true, // Make the slider loop infinitely
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

const PartnerSlider = () => {
  const { themeMode } = useContext(ThemeContext);
  const isDarkMode = themeMode === 'dark';
  const [partners, setPartners] = useState([]);  // State for storing partner data

  // Fetch partner data from API
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();

        if (data.success) {
          setPartners(data.data);  // Set partners data from API response
        }
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
    };

    fetchPartners();
  }, []);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/966553165725', '_blank');
  };

  return (
    <Box sx={{ 
      padding: { xs: '20px 10px', md: '30px 20px' }, 
      textAlign: 'center', 
      fontFamily: 'Cairo, sans-serif', 
      backgroundColor: isDarkMode ? '#121212' : '#30355b', 
      color: 'white'
    }} className="boxxcontainer">
      {/* Static Title and Description */}
      <Typography 
        variant="h2" 
        sx={{ 
          fontSize: { xs: '2em', md: '3em' }, 
          marginTop: { xs: '70px', md: "20px", sm: '50px' }, 
          marginBottom: '10px', 
          fontFamily: '"MO_Nawel", sans-serif',
          fontWeight: '800', 
          color: isDarkMode ? 'white' : 'white' 
        }}
      >
        شركاؤنا
      </Typography>
      <Typography 
        variant="body1" 
        sx={{ 
          marginBottom: '30px', 
          fontWeight: '350', 
          fontFamily: '"MO_Nawel", sans-serif',
          fontSize: { xs: '18px', md: '25px' }, 
          color: isDarkMode ? 'white' : 'white' 
        }}
      >
        نحن ننمو معًا
      </Typography>

      {/* Slider Component */}
      <Box sx={{ width: { xs: '90%', md: '80%' }, margin: '0 auto' }}>
        <Slider {...sliderSettings}>
          {partners.map((partner) => (
            <Box key={partner.id} sx={{ padding: '10px', height: '100%' }}>
              <Box sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                textAlign: 'center',
                backgroundColor: isDarkMode ? '#1e1e1e' : '#005177',
                color: isDarkMode ? 'white' : 'black',
                padding: '0px',
                height: { xs: '160px', md: '250px' },
                margin: { xs: '0 10px', md: '0 10px' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
                <Box sx={{
                  backgroundColor: isDarkMode ? '#333' : '#40CBB4',
                  padding: { xs: '5px', md: '10px' },
                  borderRadius: '10px 10px 0 0',
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: '600'
                }}>
                  <Typography variant="h6" sx={{ fontSize: { xs: '0.8em', md: '1.2em' }, margin: '0' }}>
                    {partner.name} {/* Display partner name */}
                  </Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: 1,
                  padding: '10px'
                }}>
                  <img 
                    src={`https://backendforerpsystem.zbooma.com/${partner.image_url}`} 
                    alt={`شريك ${partner.id}`} 
                    style={{ 
                      width: '100%', 
                      height: 'auto', 
                      maxHeight: { xs: '100px', md: '150px' }, 
                      objectFit: 'contain', 
                      borderRadius: '10px' 
                    }} 
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

      {/* Buttons */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        gap: '20px', 
        marginTop: '20px', 
        flexWrap: 'wrap' 
      }}>
        <Button 
          variant="outlined" 
          sx={{ 
            borderColor: isDarkMode ? 'white' : 'white', 
            color: isDarkMode ? 'white' : 'white', 
            '&:hover': { backgroundColor: isDarkMode ? '#333' : '#005177', color: 'white' }, 
            fontFamily: 'Cairo, sans-serif',
            padding: { xs: '10px 20px', md: '15px 30px' },
            fontSize: { xs: '1rem', md: '1.2rem' },
            minWidth: '150px',
          }}
        >
          عنا
        </Button>
        <Button 
          variant="contained" 
          startIcon={<WhatsAppIcon />} 
          sx={{ 
            backgroundColor: '#25D366', 
            color: '#fff', 
            '&:hover': { backgroundColor: '#1ebd59' }, 
            fontFamily: 'Cairo, sans-serif',
            padding: { xs: '10px 20px', md: '15px 30px' },
            fontSize: { xs: '1rem', md: '1.2rem' },
            minWidth: '150px',
          }}
          onClick={handleWhatsAppClick}
        >
          تواصل معنا
        </Button>
      </Box>

      {/* Global Styles */}
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap');
        
        body {
          font-family: 'Cairo', sans-serif;
        }

        .partner-slider-slide {
          padding: 10px;
        }
            @media (max-width: 640px) {
              .boxxcontainer{
                margin-top:-290px
              }
          }
      `}</style>
    </Box>
  );
};

export default PartnerSlider;
