import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, IconButton, CircularProgress } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '../ThemeContext';
import axios from 'axios';

const NextArrow = ({ onClick }) => (
  <button className="pro-sec-slick-next" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronRight} />
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button className="pro-sec-slick-prev" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronLeft} />
  </button>
);

const sections = [
  { id: 'uiux', name: 'UI/UX' },
  { id: 'software', name: 'Software' },
  { id: 'mobileapp', name: 'Mobile App' },
  { id: 'photography', name: 'Photography' },
  { id: 'socialmedia', name: 'Social Media' },
  { id: 'digitalmarketing', name: 'Digital Marketing' },
  { id: 'branding', name: 'Branding' },
  { id: 'seo ', name: 'seo ' },
  { id: 'ads', name: 'ads' },
];

const horizontalSliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const verticalSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false
      }
    }
  ]
};

const desktopSliderSettings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};

const SliderComponent = () => {
  const { themeMode } = useContext(ThemeContext);
  const isDarkMode = themeMode === 'dark';

  const [activeSection, setActiveSection] = useState('uiux');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState(null);
  const [sliderContent, setSliderContent] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSliderContent();
  }, []);

  const fetchSliderContent = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backendforerpsystem.zbooma.com/getSliderContent.php');
      if (response.data.success) {
        setSliderContent(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching slider content:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (image) => {
    setDialogImage(image);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogImage(null);
  };

  return (
    <>
      <Box className="boccontainer" sx={{ width: "100%", alignItems: "center", mb: 2, overflowX: 'hidden',transform: {
            xs: 'scale(1)', // Default scale for mobile and smaller screens
            // md: 'scale(0.7)' // Scale down to 80% for laptop screens (md breakpoint and above)
          }, }}>
        <Typography variant="h4" sx={{
          fontFamily: "'MO_Nawel',sans-serif",
          color: 'white',
          textAlign: "right",
          paddingRight: "10px",
          fontSize: "40px"
        }}>
          خدمـــاتنا
        </Typography>
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <hr style={{ color: "white", border: "none", borderBottom: "2px solid white", marginRight: "10px" }} />
        </Box>
      </Box>

      <Box  sx={{ marginTop: '20px', width: '100%', overflowX: 'hidden' }}>
        <Slider style={{transform: {
            xs: 'scale(1)', // Default scale for mobile and smaller screens
            md: 'scale(0.7)' // Scale down to 80% for laptop screens (md breakpoint and above)
          },}} {...desktopSliderSettings}>
          {sections.map((section) => (
            <Box key={section.id} sx={{ padding: '0 10px', boxSizing: 'border-box' }}>
              <Button
                variant={isDarkMode ? 'outlined' : 'contained'}
                sx={{
                  borderColor: isDarkMode ? '#fff' : '#004d66',
                  color: isDarkMode ? '#fff' : '#fff',
                  backgroundColor: activeSection === section.id ? '#42aaa6' : (isDarkMode ? 'transparent' : '#919191'),
                  padding: { xs: '5px 10px', md: '10px 20px' },
                  fontSize: { xs: '0.8rem', md: '1rem' },
                  fontFamily: '"Cairo", sans-serif',
                  width: '100%',
                  borderRadius: '20px',
                  margin: '10px',
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: isDarkMode ? '#fff' : '#003d52',
                    backgroundColor: activeSection === section.id ? '#42aaa6' : (isDarkMode ? 'transparent' : '#003d52'),
                  }
                }}
                onClick={() => setActiveSection(section.id)}
              >
                {section.name}
              </Button>
            </Box>
          ))}
        </Slider>
      </Box>

      <Box sx={{ marginTop: '40px', width: '100%', overflowX: 'hidden'}}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Slider style={{marginRight:"10px"}} {...horizontalSliderSettings}>
            {sliderContent[activeSection]?.images.map((image, index) => (
              <Box key={index} className="pro-sec-slider-item" sx={{ borderRadius: '10px', margin: '0 5px' }}>
                <Slider style={{ border: isDarkMode ? '1px solid white' : '1px solid #005177', borderRadius: '20px', margin: '10px' }} {...verticalSliderSettings}>
                  {[image, image].map((verticalContent, verticalIndex) => (
                    <Box key={verticalIndex} className="pro-sec-slider-item-vertical" sx={{ padding: '5px', margin: '20px 0', display: 'flex', alignItems: 'center' }}>
                      <img
                        src={`https://backendforerpsystem.zbooma.com/${verticalContent}`}
                        alt={`Slide ${verticalIndex}`}
                        style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '10px', cursor: 'pointer' }}
                        onClick={() => handleImageClick(verticalContent)}
                      />
                    </Box>
                  ))}
                </Slider>
              </Box>
            ))}
          </Slider>
        )}
      </Box>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8, color: isDarkMode ? 'white' : 'grey' }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={`https://backendforerpsystem.zbooma.com/${dialogImage}`} alt="Dialog" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
        </DialogContent>
      </Dialog>

      <style jsx>{`
        .pro-sec-slick-prev,
        .pro-sec-slick-next {
          background-color: ${isDarkMode ? '#005177' : '#0073aa'};
          color: ${isDarkMode ? 'white' : 'black'};
          border: none;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: background-color 0.3s;
          z-index: 1;
        }

        .pro-sec-slick-prev:hover,
        .pro-sec-slick-next:hover {
          background-color: ${isDarkMode ? '#003d4d' : '#005177'};
        }

        .pro-sec-slick-prev {
          position: absolute;
          left: -50px;
          top: 50%;
          transform: translateY(-50%);
        }

        .pro-sec-slick-next {
          position: absolute;
          right: -50px;
          top: 50%;
          transform: translateY(-50%);
        }

        .pro-sec-slider-item {
          margin: 0 5px;
        }

        .pro-sec-slider-item-vertical {
          margin: 20px 0;
        }

        @media (max-width: 580px) {
          .pro-sec-professional-section {
            margin-top: 150px;
          }
            .boccontainer{ 
            margin-top: -270px; 
            }
        }
      `}</style>
    </>
  );
};

export default SliderComponent;
