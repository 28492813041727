import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  CircularProgress,
  IconButton,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Delete, AddPhotoAlternate, ExpandMore, Edit } from '@mui/icons-material';
import swal from 'sweetalert';
import Slider from 'react-slick';
import NavBar from '../../Components/NavBar/NavBar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ToolsSlider from '../../Components/ToolsSliderEditDashboard';
import VideoGallery from '../../Components/vediosChnageDashborad';
import PartnersChangeContent from '../../Components/PartnersChangeContent';
import CustomersManagement from '../../Components/CustomersManagement';
import OurServicesManager from '../../Components/OurServicesManager';
import ManageStats from '../../Components/ManageStatsSection';
import CommonQuestionsManger from '../../Components/CommonQuestionsManagement';
import MdowanaManagement from '../../Components/MdowanaManagement';
import PortfolioManagement from '../../Components/ProtifoloFirstectionManagement';
import ProfessionalSectionManagement from '../../Components/ProfessionalSectionManagement';
import StatsSectionMnager from '../../Components/StatsSectionManagement';
import ServicesImagesProtofiloManager from '../../Components/servicesImagesProtofiloManager';
import ContactUsSectionProtofiloManagement from '../../Components/ContactUsSectionProtofiloManagement';
import VideoGalleryNew from '../../Components/vediosChnageDashboradNew';

const DashboardForChangeContent = () => {
  const [services, setServices] = useState([]);
  const [editingService, setEditingService] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [sliderContent, setSliderContent] = useState({});
  const [cardSliderContent, setCardSliderContent] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [currentEditCard, setCurrentEditCard] = useState(null); // Line 405
  const [openDialog, setOpenDialog] = useState(false); // Line 413

  const [formData, setFormData] = useState({
    service_name: '',
    description: '',
    image_url: '',
    image_file: null,
    steps: [{ title: '', content: '' }],
  });

  const [interfaceContent, setInterfaceContent] = useState({
    id: null,
    background_image_file: null,
    first_image_file: null,
    typical_typography: [{ text: '', delay: 2000 }],
    button1_text: '',
    button2_text: '',
  });

  const [imagePreviews, setImagePreviews] = useState({
    backgroundImagePreview: null,
    firstImagePreview: null,
  });

  useEffect(() => {
    fetchServices();
    fetchInterfaceContent();
    fetchSliderContent();
    fetchCardSliderContent();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backendforerpsystem.zbooma.com/handleservices.php');
      const servicesData = response.data.map((service) => ({
        ...service,
        image_url: service.image_url ? `https://backendforerpsystem.zbooma.com/${service.image_url}` : '',
        steps: Array.isArray(service.steps) ? service.steps : JSON.parse(service.steps || '[]'),
      }));
      setServices(servicesData);
    } catch (error) {
      console.error('Error fetching services:', error);
      swal('Error', 'Failed to fetch services. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchInterfaceContent = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backendforerpsystem.zbooma.com/getHeroSection.php');
      if (response.data.success && response.data.data.length > 0) {
        const fetchedData = response.data.data[0];
        setInterfaceContent({
          id: fetchedData.id,
          typical_typography: Array.isArray(fetchedData.typical_typography)
            ? fetchedData.typical_typography
            : JSON.parse(fetchedData.typical_typography || '[]'),
          button1_text: fetchedData.button1_text || '',
          button2_text: fetchedData.button2_text || '',
          background_image_file: null,
          first_image_file: null,
        });
        setImagePreviews({
          backgroundImagePreview: fetchedData.background_image_url ? `https://backendforerpsystem.zbooma.com/${fetchedData.background_image_url}` : null,
          firstImagePreview: fetchedData.first_image_url ? `https://backendforerpsystem.zbooma.com/${fetchedData.first_image_url}` : null,
        });
      } else {
        swal('Error', 'Failed to fetch interface content. Please try again later.', 'error');
      }
    } catch (error) {
      console.error('Error fetching interface content:', error);
      swal('Error', 'Failed to fetch interface content. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchSliderContent = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backendforerpsystem.zbooma.com/getSliderContent.php');
      if (response.data.success) {
        setSliderContent(response.data.data);
      } else {
        swal('Error', 'Failed to fetch slider content. Please try again later.', 'error');
      }
    } catch (error) {
      console.error('Error fetching slider content:', error);
      swal('Error', 'Failed to fetch slider content. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchCardSliderContent = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backendforerpsystem.zbooma.com/getCardSlider.php');
      if (response.data.success) {
        setCardSliderContent(response.data.data);
      } else {
        swal('Error', 'Failed to fetch card slider content. Please try again later.', 'error');
      }
    } catch (error) {
      console.error('Error fetching card slider content:', error);
      swal('Error', 'Failed to fetch card slider content. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInterfaceChange = (e) => {
    const { name, value } = e.target;
    setInterfaceContent({ ...interfaceContent, [name]: value });
  };

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (type === 'background') {
        setInterfaceContent({ ...interfaceContent, background_image_file: file });
        setImagePreviews({
          ...imagePreviews,
          backgroundImagePreview: URL.createObjectURL(file),
        });
      } else if (type === 'first') {
        setInterfaceContent({ ...interfaceContent, first_image_file: file });
        setImagePreviews({
          ...imagePreviews,
          firstImagePreview: URL.createObjectURL(file),
        });
      }
    }
  };

  const handleDeleteSliderImage = (key, index) => {
    const newImages = sliderContent[key].images.filter((_, i) => i !== index);
    setSliderContent({
      ...sliderContent,
      [key]: {
        ...sliderContent[key],
        images: newImages,
      },
    });
  };

  const handleEditSlider = (key) => {
    setEditMode((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleStepChange = (index, key, value) => {
    const newSteps = [...formData.steps];
    newSteps[index][key] = value;
    setFormData({ ...formData, steps: newSteps });
  };

  const handleTypicalStepChange = (index, key, value) => {
    const newSteps = [...interfaceContent.typical_typography];
    newSteps[index][key] = value;
    setInterfaceContent({ ...interfaceContent, typical_typography: newSteps });
  };

  const addNewStep = () => {
    setFormData({
      ...formData,
      steps: [...formData.steps, { title: '', content: '' }],
    });
  };

  const addNewTypicalStep = () => {
    setInterfaceContent({
      ...interfaceContent,
      typical_typography: [
        ...interfaceContent.typical_typography,
        { text: '', delay: 2000 },
      ],
    });
  };

  const removeStep = (index) => {
    const newSteps = [...formData.steps];
    newSteps.splice(index, 1);
    setFormData({ ...formData, steps: newSteps });
  };

  const removeTypicalStep = (index) => {
    const newSteps = [...interfaceContent.typical_typography];
    newSteps.splice(index, 1);
    setInterfaceContent({ ...interfaceContent, typical_typography: newSteps });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('service_name', formData.service_name);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('image_file', formData.image_file);
      formDataToSend.append('steps', JSON.stringify(formData.steps));

      if (editingService) {
        formDataToSend.append('id', editingService.id);
      }

      await axios.post('https://backendforerpsystem.zbooma.com/handleservices.php', formDataToSend);

      swal('Success', editingService ? 'Service updated successfully!' : 'Service added successfully!', 'success');
      fetchServices();
      setFormData({
        service_name: '',
        description: '',
        image_url: '',
        image_file: null,
        steps: [{ title: '', content: '' }],
      });
      setImagePreview(null);
      setEditingService(null);
    } catch (error) {
      console.error('Error saving service:', error);
      swal('Error', 'Failed to save service. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleInterfaceSave = async () => {
    setLoading(true);
    try {
      const formDataToSend = new FormData();

      if (interfaceContent.id) {
        formDataToSend.append('id', interfaceContent.id);
      }

      if (interfaceContent.background_image_file) {
        formDataToSend.append('background_image_file', interfaceContent.background_image_file);
      } else {
        formDataToSend.append('background_image_url', imagePreviews.backgroundImagePreview);
      }

      if (interfaceContent.first_image_file) {
        formDataToSend.append('first_image_file', interfaceContent.first_image_file);
      } else {
        formDataToSend.append('first_image_url', imagePreviews.firstImagePreview);
      }

      formDataToSend.append('typical_typography', JSON.stringify(interfaceContent.typical_typography));
      formDataToSend.append('button1_text', interfaceContent.button1_text);
      formDataToSend.append('button2_text', interfaceContent.button2_text);

      const response = await axios.post('https://backendforerpsystem.zbooma.com/handleelwghaa.php', formDataToSend);

      if (response.data.success) {
        swal('Success', 'Interface content updated successfully!', 'success');
        fetchInterfaceContent();
      } else {
        console.error('Error response:', response.data);
        swal('Error', response.data.message || 'Failed to save interface content', 'error');
      }
    } catch (error) {
      console.error('Error saving interface content:', error);
      swal('Error', 'Failed to save interface content. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSliderImageUpload = async (e, sectionId) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('section_id', sectionId); 

      try {
        const response = await axios.post('https://backendforerpsystem.zbooma.com/upload_image.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.success) {
          const newImagePath = response.data.file_path;
          setSliderContent((prevState) => ({
            ...prevState,
            [sectionId]: {
              ...prevState[sectionId],
              images: [...prevState[sectionId].images, newImagePath],
            },
          }));
        } else {
          swal('Error', 'Failed to upload image. Please try again.', 'error');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        swal('Error', 'Network error while uploading image. Please try again.', 'error');
      }
    }
  };

  const handleSaveSliderContent = async (key) => {
    setLoading(true);
    try {
      const data = {
        section_id: key,
        button_name: key,
        images: sliderContent[key].images,
      };

      const response = await axios.put('https://backendforerpsystem.zbooma.com/handleSliderContent.php', data);

      if (response.data.success) {
        swal('Success', 'Slider content updated successfully!', 'success');
        setEditMode((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      } else {
        swal('Error', response.data.message || 'Failed to update slider content', 'error');
      }
    } catch (error) {
      console.error('Error updating slider content:', error);
      swal('Error', 'Failed to update slider content. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (service) => {
    setEditingService(service);
    setFormData({
      ...service,
      steps: Array.isArray(service.steps)
        ? service.steps
        : JSON.parse(service.steps || '[]'),
    });
    setImagePreview(service.image_url);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete('https://backendforerpsystem.zbooma.com/handleservices.php', { data: { id } });
      fetchServices();
      swal('Deleted!', 'Service has been deleted.', 'success');
    } catch (error) {
      console.error('Error deleting service:', error);
      swal('Error', 'Failed to delete service. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAddImageToCard = (e) => {
    const file = e.target.files[0];
    if (file) {
        // Create a URL for the image preview
        const newImageUrl = URL.createObjectURL(file);

        // Attach the file object so we can send it to the backend later
        setCurrentEditCard((prevCard) => ({
            ...prevCard,
            images: [...prevCard.images, { url: newImageUrl, file }],  // Store the file along with the preview URL
        }));
    }
};

  

const handleDeleteCardImage = (index) => {
  const imageToDelete = currentEditCard.images[index];  // The image to be deleted
  const updatedImages = currentEditCard.images.filter((_, i) => i !== index);

  setCurrentEditCard((prevCard) => ({ ...prevCard, images: updatedImages }));  // Update UI immediately

  // Prepare the form data to send to the server
  const formData = new FormData();
  formData.append('id', currentEditCard.id);  // Ensure you send the correct card ID
  formData.append('deleteImages', JSON.stringify([imageToDelete]));  // Send the image to be deleted as JSON

  // Send the request to the backend to delete the image
  axios.post('https://backendforerpsystem.zbooma.com/updateCardSlider.php', formData)
    .then((response) => {
      if (response.data.success) {
        console.log('Image deleted successfully');
      } else {
        console.error('Failed to delete image:', response.data.message);
      }
    })
    .catch((error) => {
      console.error('Error deleting image:', error);
    });
};


  

  const handleEditCardSlider = (card) => {
    setCurrentEditCard(card);
    setOpenDialog(true);
  };

  const handleCardTitleChange = (e) => {
    const { value } = e.target;
    setCurrentEditCard((prevCard) => ({ ...prevCard, title: value }));
  };

  const handleSaveCardChanges = async () => {
    try {
      const formData = new FormData();
  
      // Add card ID and title
      formData.append('id', currentEditCard.id);
      formData.append('title', currentEditCard.title);
  
      // Add new images to the formData
      currentEditCard.images.forEach((image, index) => {
        if (image.file) {
          formData.append('newImages[]', image.file);  // Append each new file
        }
      });
  
      // Add deleted images
      const deletedImages = currentEditCard.deletedImages || [];
      formData.append('deleteImages', JSON.stringify(deletedImages));  // Send deleted images as JSON
  
      // Send the request to the backend
      const response = await axios.post(
        'https://backendforerpsystem.zbooma.com/updateCardSlider.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.data.success) {
        setOpenDialog(false);
        fetchCardSliderContent();  // Re-fetch card slider content after successful save
        swal('Success', 'Card slider updated successfully!', 'success');
      } else {
        console.log('Response error:', response.data.message);
        swal('Error', 'Failed to update card slider.', 'error');
      }
    } catch (error) {
      console.error('Save error:', error);
      swal('Error', 'Failed to update card slider.', 'error');
    }
  };
  
  
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (token) {
      setIsAuthorized(true);
    }
    setLoading(false); // Once check is done, stop loading
  }, []);

  if (!isAuthorized) {
    return (
      <div style={{ textAlign: 'center', fontFamily: 'Cairo', padding: '50px' }}>
        <Typography variant="h4" color="error">
          انت غير مصرح لك الدخول هنا
        </Typography>
      </div>
    );
  }
  
  return (
    <>
      <NavBar />
      <Container style={{ marginTop: '50px' }} maxWidth="lg">
        <Typography
          style={{
            textAlign: 'center',
            fontFamily: '"MO_Nawel", sans-serif',
            fontWeight: '800',
            fontSize: '50px',
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          تعديل الخدمات
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Card variant="outlined" sx={{ mb: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {editingService ? 'Update Service' : 'Add Service'}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Service Name"
                      name="service_name"
                      value={formData.service_name}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                      startIcon={<AddPhotoAlternate />}
                    >
                      Upload Image
                      <input type="file" hidden onChange={(e) => setFormData({ ...formData, image_file: e.target.files[0] })} />
                    </Button>
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto', marginTop: 16 }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Service Description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>

                  {formData.steps.map((step, index) => (
                    <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          label={`Step Title ${index + 1}`}
                          value={step.title}
                          onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          multiline
                          rows={5}
                          label={`Step Content ${index + 1}`}
                          value={step.content}
                          onChange={(e) => handleStepChange(index, 'content', e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton color="secondary" onClick={() => removeStep(index)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button variant="outlined" onClick={addNewStep}>
                      Add New Step
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      fullWidth
                    >
                      {editingService ? 'Update Service' : 'Add Service'}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Grid container spacing={4}>
              {services.map((service) => (
                <Grid item xs={12} sm={6} md={4} key={service.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {service.service_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {service.description}
                      </Typography>
                      <img
                        src={service.image_url}
                        alt={service.service_name}
                        style={{ width: '100%', height: 'auto', marginTop: 16 }}
                      />
                      <Accordion sx={{ mt: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>View Steps</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {Array.isArray(service.steps) && service.steps.length > 0 ? (
                            service.steps.map((step, index) => (
                              <Paper key={index} sx={{ p: 2, mt: 1 }}>
                                <Typography variant="body1">
                                  {step.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {step.content}
                                </Typography>
                              </Paper>
                            ))
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              No steps available.
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(service)}
                        sx={{ mt: 2 }}
                        fullWidth
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(service.id)}
                        sx={{ mt: 1 }}
                        fullWidth
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Card variant="outlined" sx={{ mb: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  تغير الواجهة
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                      startIcon={<AddPhotoAlternate />}
                    >
                      Upload Background Image
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleImageUpload(e, 'background')}
                      />
                    </Button>
                    {imagePreviews.backgroundImagePreview && (
                      <img
                        src={imagePreviews.backgroundImagePreview}
                        alt="Background Preview"
                        style={{ width: '100%', height: 'auto', marginTop: 16 }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                      startIcon={<AddPhotoAlternate />}
                    >
                      Upload First Image
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleImageUpload(e, 'first')}
                      />
                    </Button>
                    {imagePreviews.firstImagePreview && (
                      <img
                        src={imagePreviews.firstImagePreview}
                        alt="First Image Preview"
                        style={{ width: '100%', height: 'auto', marginTop: 16 }}
                      />
                    )}
                  </Grid>
                  {interfaceContent.typical_typography.map((step, index) => (
                    <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          label={`Typical Step ${index + 1}`}
                          value={step.text}
                          onChange={(e) => handleTypicalStepChange(index, 'text', e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          label={`Delay ${index + 1} (ms)`}
                          value={step.delay}
                          onChange={(e) => handleTypicalStepChange(index, 'delay', e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          color="secondary"
                          onClick={() => removeTypicalStep(index)}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button variant="outlined" onClick={addNewTypicalStep}>
                      Add New Typical Step
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Button 1 Text"
                      name="button1_text"
                      value={interfaceContent.button1_text}
                      onChange={handleInterfaceChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Button 2 Text"
                      name="button2_text"
                      value={interfaceContent.button2_text}
                      onChange={handleInterfaceChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleInterfaceSave}
                      fullWidth
                    >
                      Save Interface Content
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card variant="outlined" sx={{ mb: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  تغير محتوى الاسلادير الثاني
                </Typography>
                <Grid container spacing={2}>
                  {Object.keys(sliderContent).map((key) => (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" component="h2">
                            {sliderContent[key].section_id}
                          </Typography>
                          {sliderContent[key].images.map((image, index) => (
                            <div key={index} style={{ position: 'relative', marginTop: 16 }}>
                              <img
                                src={`https://backendforerpsystem.zbooma.com/${image}`}
                                alt={`Slider ${key} ${index}`}
                                style={{ width: '100%', height: 'auto' }}
                              />
                              {editMode[key] && (
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleDeleteSliderImage(key, index)}
                                  style={{ position: 'absolute', top: 0, right: 0 }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </div>
                          ))}
                          {editMode[key] && (
                            <Button
                              variant="contained"
                              component="label"
                              fullWidth
                              startIcon={<AddPhotoAlternate />}
                              sx={{ mt: 2 }}
                            >
                              Add Image
                              <input
                                type="file"
                                hidden
                                onChange={(e) => handleSliderImageUpload(e, key)}
                              />
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color={editMode[key] ? "secondary" : "primary"}
                            onClick={() => handleEditSlider(key)}
                            sx={{ mt: 2 }}
                            fullWidth
                            startIcon={<Edit />}
                          >
                            {editMode[key] ? "Finish Editing" : "Edit"}
                          </Button>
                          {editMode[key] && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleSaveSliderContent(key)}
                              sx={{ mt: 2 }}
                              fullWidth
                            >
                              Save Changes
                            </Button>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>

            <Card variant="outlined" sx={{ mb: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  تغير محتوى الكارد سلايدر - مهاراتنا
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Box sx={{ marginTop: '40px', width: '100%', overflowX: 'hidden' }}>
                    <Slider {...sliderSettings}>
                      {cardSliderContent.map((card, index) => (
                        <Box key={index} sx={{ padding: '10px' }}>
                          <Card sx={{ display: 'inline-block', borderRadius: '16px', minWidth: 250, maxWidth: 300, mx: 'auto' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '10px' }}>
                                {card.title}
                              </Typography>
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', mt: 1 }}>
                                {card.images?.map((image, imageIndex) => (
                                  <Box key={imageIndex} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1 }}>
                                    <img
                                      src={`https://backendforerpsystem.zbooma.com/${image}`}
                                      alt={`icon-${imageIndex}`}
                                      style={{ height: 50, margin: '10px 0' }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditCardSlider(card)}
                                sx={{ mt: 2 }}
                                fullWidth
                              >
                                Edit
                              </Button>
                            </CardContent>
                          </Card>
                        </Box>
                      ))}
                    </Slider>
                  </Box>
                )}
              </CardContent>
            </Card>

            {currentEditCard && (
              <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>Edit Card: {currentEditCard.title}</DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    label="Card Title"
                    value={currentEditCard.title}
                    onChange={handleCardTitleChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                   <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', mt: 1 }}>
  {currentEditCard.images?.map((image, index) => (
    <Box key={index} sx={{ position: 'relative' }}>
      {/* Ensure image URL has the correct path */}
      <img
        src={image.url || `https://backendforerpsystem.zbooma.com/${image}`}  // Use `url` for preview or the full image path for existing images
        alt={`image-${index}`}
        style={{ height: 100, width: 100, objectFit: 'cover', margin: '10px', borderRadius: '5px' }}
      />
      <IconButton
        color="secondary"
        onClick={() => handleDeleteCardImage(index)}  // Handle image deletion
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <Delete />
      </IconButton>
    </Box>
  ))}
</Box>

                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    startIcon={<AddPhotoAlternate />}
                    sx={{ mt: 2 }}
                  >
                    Add Image
                    <input type="file" hidden onChange={handleAddImageToCard} />
                  </Button>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenDialog(false)} color="secondary">Cancel</Button>
                  <Button onClick={handleSaveCardChanges} color="primary">Save Changes</Button>
                </DialogActions>
              </Dialog>
            )}
          </>
        )}
      </Container>
      <ToolsSlider/>
      <VideoGallery/>
      <VideoGalleryNew/>
      <PartnersChangeContent/>
      <CustomersManagement/>
      <OurServicesManager/>
      <ManageStats/>
      <CommonQuestionsManger/>
      <MdowanaManagement/>
      <h3 style={{textAlign:"center",fontFamily:"Cairo"}}>تغير محتوى صفحة الملف الشخصي</h3>
      <hr/>
      <PortfolioManagement/>
      <hr/>
       <ProfessionalSectionManagement/>
       <hr/>
       <StatsSectionMnager/>
       <hr/>
       <ServicesImagesProtofiloManager/>
       <hr/>
       <ContactUsSectionProtofiloManagement/>
       <hr/>
    </>
  );
};

export default DashboardForChangeContent;
