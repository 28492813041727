import React, { useEffect, useState } from 'react';
import './ProfessionalSection.css';
import StatsSection from '../ThirdSection/ThirdSection';
import ProfessionalSectionFourth from '../FourtSection/FourtSection';
import AppointmentBooking from '../FifeSection/FifeSection';
import ContactUsSection from '../ContactFooter/ContactFooter';

const ProfessionalSection = () => {
  const [sectionData, setSectionData] = useState(null);

  useEffect(() => {
    fetch('https://backendforerpsystem.zbooma.com/professional_section.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Adjust image URLs if necessary
          const baseURL = 'https://backendforerpsystem.zbooma.com';
          const adjustedData = {
            ...data.data[0],
            cards: data.data[0].cards.map((card) => ({
              ...card,
              card_image_url: `${baseURL}${card.card_image_url}`,
            })),
          };
          setSectionData(adjustedData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  if (!sectionData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="professional-section">
        <div className="cards-section">
          {sectionData.cards.map((card, index) => (
            <div className="card" key={index}>
              <img src={card.card_image_url} alt={card.card_title} />
              <h3>{card.card_title}</h3>
              <p>{card.card_description}</p>
            </div>
          ))}
        </div>
        <div style={{textAlign:"right"}} className="text-section">
          <h1>{sectionData.main_title}</h1>
          <p style={{textAlign:"right",fontSize:"25px"}}>{sectionData.subtitle}</p>
          <button style={{fontSize:"25px"}} className="contact-button">{sectionData.button_text}</button>
        </div>
      </div>
      <StatsSection />
      <ProfessionalSectionFourth />
      <AppointmentBooking />
      <ContactUsSection />
    </>
  );
};

export default ProfessionalSection;
